<template>
  <div class="box22 container" style="background-color: #fff;">
    <!-- 搜索筛选 -->
    <el-form :inline="true" :model="searchObj" class="user-search flex-between" @submit.native.prevent>
      <div>
        <templateTitle title="权限管理" :isBack="false"></templateTitle>
      </div>

      <div class="flex" style="margin: 10px 0;">
        <div style="margin-right: 10px;">
          <el-button
            size="medium"
            type="primary"
            plain
            @click="webSubmit"
            v-if="1 || (hasPerm('pro:authorMag:mag') && userCode != 'sys_tenant_manager_role')"
            >提交</el-button
          >
        </div>
      </div>
    </el-form>
    <authorMagPc ref="authorMagPc" class="table-padding flex" @getCode="getCode" />
  </div>
</template>
<script>
import authorMagPc from './components/authorMagPc.vue'
import templateTitle from '@/components/templateTitle.vue'

export default {
  name: 'roleMenuDrawer',
  data() {
    return {
      userCode: '',
      searchObj: {
        name: ''
      }, //搜索字段
      loading: false //是显示加载
    }
  },
  components: {
    templateTitle,
    authorMagPc,
  },
  created() {},
  mounted() {},
  methods: {
    getCode(val) {
      console.log(val, 'getCode')
      this.userCode = val
    },
    webSubmit() {
      // this.$refs.authorMagPc.handleSubmit()
      this.$refs.authorMagPc.handleSubmit1()
    },
  }
}
</script>
<style lang="scss" scoped>
.role-list {
  width: 200px;
  height: auto;
  // border: 1px solid red;
  border-right: 1px solid #ccc;
  margin-right: 5px;
  .role-search {
    width: 98%;
    margin-bottom: 5px;
    // padding: 2% 1%;
  }
  .role-list-item {
    width: 98%;
    height: 50px;
    border: 1px solid #e5e8ef;
    margin-bottom: 5px;
    padding: 2% 4%;
    max-height: 750px;
    overflow-y: auto;
    // checklist
    .item-box {
      width: 100%;
      height: 100%;
      cursor: pointer;
      display: flex;
      align-items: center;
      color: #010b1f;
      font-size: 15px;
    }
  }
}
.role-table {
  width: calc(100% - 200px);
}
</style>
